header {
    height: 150vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative ;
}

/* ============== CTA =============== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

/* ============== HeaderSocials =============== */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8 rem;
    position: absolute;
    left: 0;
    bottom: 13rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ============== Image =============== */

.me {
    /* background: linear-gradient(var(--color-primary),transparent); */
    position: absolute;
    /* left: calc(50% - 11rem); */
    left:50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    margin-top: 3rem;
}

/* ============== Scroll =============== */
.scroll__down {
    position: absolute;
    /* right: -2.3rem;
    bottom: 5rem; */
    right: 6rem;
    bottom: 16rem;
    transform: rotate(90deg);
    font-weight:300;
    font-size: 0.9rem;
}



/* =============== MEDIA QUERIES (MEDIUM DEVICES E.G.TABLET) ==================== */ 

@media screen and (max-width:1024px) {
    header {
        height: 55vh !important;
        overflow: hidden;
    }
    
    .container {
        height: 60vh;
    }

    .header__socials {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.8 rem;
        position: absolute;
        left: 8rem;
        bottom: 13rem;
    }

    
}

/* =============== MEDIA QUERIES (SMALL DEVICES) ==================== */ 

@media screen and (max-width:600px) {
    .container {
        height: 70vh;
    }

    header {
        height: 62vh !important;
        overflow: hidden;
    }

    .header__socials, .scroll__down {
        display: none;
    }


}